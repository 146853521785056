import { navigate, PageProps } from 'gatsby'
import { useLocation } from '@reach/router'
import React, { useEffect, useRef, useState } from 'react'
import { LayoutProps } from '../../../../components/Layout.context'
import { BookingRequest } from '../../../../models/BookingRequest'
import { BookingFormData } from '../../../../models/BookingFormData'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import BookingFormSchema from '../../../../validations/BookingFormSchema'
import ReCAPTCHA from 'react-google-recaptcha'
import useFormError from '../../../../hooks/useFormError'
import fetchSaveFile from '../../../../utils/fetchSaveFile'
import BookingForm from './BookingForm'
import BookingConfirmation from './BookingConfirmation'
import BookingError from './BookingError'
import useSaveShopsQuery from '../../../../hooks/useSaveShopsQuery'
import BookingContent, { Step } from '../../../../components/BookingContent'
import theme from '../../../../theme/theme'
import { Helmet } from 'react-helmet'
import SmartphoneCard from '../../SmartphoneCard'
import CustomerReviews from '../../CustomerReviews'
import {
  getLocalStorage,
  setLocalStorage,
} from '../../../../utils/manageLocalStorage'
import { customDecode } from '../../../../utils/manageUrl'
import {
  Smartphone,
  fetchSmartphoneStock,
  BookingResponse,
} from '../../../../utils/manageSmartphoneStock'
import shopIcon from '../../../../images/shop_Icon.png'
import BookingNotAvailableError from './BookingNotAvailableError'
import { recLink } from '../../../../data/menu'

const smartphone: React.FC<PageProps> = () => {
  //Steps for navigation
  const [step, setStep] = useState('selection')

  const shops = useSaveShopsQuery()

  //Booked information
  const [bookedData, setBookedData] = useState<BookingResponse>(
    {} as BookingResponse
  )

  const handleSelectionButton = () => {
    setStep('booking')
  }

  const handleCloseToSelection = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset()
    }
    setStep('selection')
  }

  const handleCloseToHome = () => {
    navigate('/')
  }

  //Fetch variables in path
  const location = useLocation()
  const pathname = location.pathname.split('/')
  const shopId = pathname[2]

  useEffect(() => {
    setValue('shopId', shopId)
  }, [])

  const modele = customDecode(pathname[3])

  const [loading, setLoading] = useState<boolean>(true)

  //Fetch smartphone stock
  const [recommendedList, setRecommendedList] = useState<Smartphone[]>([])
  const [smartphoneList, setSmartphoneList] = useState<Smartphone[]>([])

  useEffect(() => {
    const fetchAndSetProducts = async () => {
      if (!getLocalStorage(`cacheShopData_${shopId}`)) {
        const data = await fetchSmartphoneStock(shopId)
        setLocalStorage(`cacheShopData_${shopId}`, data)
      }
      const shopStock =
        getLocalStorage<Smartphone[]>(`cacheShopData_${shopId}`) ?? []

      const smartphoneList =
        shopStock
          .filter((phone) => phone.modele === modele)
          .filter((phone) => phone.shopId === shopId) ?? []
      setSmartphoneList(smartphoneList)
      const capacityOptions = getCapacityOptions(smartphoneList)
      setCapacityOptions(capacityOptions)
      handleCapacityChange(smartphoneList, capacityOptions[0])

      const minPrice = smartphoneList
        .map((phone) => phone.buyAmountTTC)
        .reduce((min, current) => (current < min ? current : min), Infinity)
      const recommendedMap: { [key: string]: Smartphone } = {}

      if (smartphoneList.length > 0) {
        shopStock
          .filter((phone) => phone.shopId === shopId)
          .filter((phone) => phone.buyAmountTTC <= minPrice + 200)
          .filter((phone) => phone.modele !== modele)
          .filter((phone) => phone.marque === smartphoneList[0].marque)
          .forEach((phone) => {
            if (
              !recommendedMap[phone.modele] ||
              phone.buyAmountTTC < recommendedMap[phone.modele].buyAmountTTC
            ) {
              recommendedMap[phone.modele] = phone
            }
          })
        setRecommendedList(
          Object.values(recommendedMap)
            .sort((a, b) => b.buyAmountTTC - a.buyAmountTTC)
            .slice(0, 4)
        )
      }
      setLoading(false)
    }
    fetchAndSetProducts()
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])

  const [capacityOptions, setCapacityOptions] = useState<string[]>([])
  const [colorOptions, setColorOptions] = useState<string[]>([])
  const [gradeOptions, setGradeOptions] = useState<string[]>([])

  const getCapacityOptions = (smartphoneList: Smartphone[]) => {
    const capacitySet = new Set(smartphoneList.map((phone) => phone.capacity))
    const capacityArray = Array.from(capacitySet)
    capacityArray.sort((a, b) => {
      if (a.length === b.length) {
        return a.localeCompare(b)
      }
      return a.length - b.length
    })
    return capacityArray
  }

  const getColorOptions = (smartphoneList: Smartphone[], capacity: string) => {
    const colorSet = new Set(
      smartphoneList
        .filter((phone) => phone.capacity === capacity)
        .map((phone) => phone.color)
    )
    const colorArray = Array.from(colorSet)
    colorArray.sort((a, b) => {
      return a.localeCompare(b)
    })
    return colorArray
  }

  const getGradeOptions = (
    smartphoneList: Smartphone[],
    capacity: string,
    color: string
  ) => {
    const gradeSet = new Set(
      smartphoneList
        .filter((phone) => phone.capacity === capacity)
        .filter((phone) => phone.color === color)
        .map((phone) => phone.grade)
    )
    const gradeArray = Array.from(gradeSet)
    gradeArray.sort((a, b) => {
      return a.localeCompare(b)
    })
    return gradeArray
  }

  const [selectedCapacity, setSelectedCapacity] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedSmartphone, setSelectedSmartphone] = useState<Smartphone>(
    {} as Smartphone
  )

  const getSmartphone = (
    smartphoneList: Smartphone[],
    capacity: string,
    color: string,
    grade: string
  ) => {
    return smartphoneList
      .filter((phone) => phone.capacity === capacity)
      .filter((phone) => phone.color === color)
      .filter((phone) => phone.grade === grade)[0]
  }

  const handleCapacityChange = (
    smartphoneList: Smartphone[],
    capacity: string
  ) => {
    setSelectedCapacity(capacity)
    const colorOptions = getColorOptions(smartphoneList, capacity)
    setColorOptions(colorOptions)
    handleColorChange(smartphoneList, capacity, colorOptions[0])
  }

  const handleColorChange = (
    smartphoneList: Smartphone[],
    capacity: string,
    color: string
  ) => {
    setSelectedColor(color)
    const gradeOptions = getGradeOptions(smartphoneList, capacity, color)
    setGradeOptions(gradeOptions)
    handleGradeChange(smartphoneList, capacity, color, gradeOptions[0])
  }

  const handleGradeChange = (
    smartphoneList: Smartphone[],
    capacity: string,
    color: string,
    grade: string
  ) => {
    setSelectedGrade(grade)
    setSelectedSmartphone(getSmartphone(smartphoneList, capacity, color, grade))
  }

  //Variables for booking
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<BookingFormData>({
    resolver: yupResolver(BookingFormSchema),
    mode: 'onChange',
  })

  const [formError, setFormError] = useFormError(isSubmitting)

  const [result, setResult] = useState<BookingFormData | undefined>(undefined)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const onBookingSubmit = handleSubmit(async (data) => {
    const request: BookingRequest = {
      shopId: data.shopId,
      smartphoneReference: selectedSmartphone.reference,
      origin: 'SAVE_CO',
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      opt1: data.optin1,
      opt2: data.optin2,
      phone: data.phone ?? '',
      modele: modele,
    }

    try {
      if (recaptchaRef.current) {
        const recaptchaToken = await recaptchaRef.current.executeAsync()

        if (recaptchaToken) {
          const resp = await fetchSaveFile('/website/v1/booking', {
            method: 'POST',
            body: JSON.stringify(request),
          })
          const text = await resp.json()
          // Reset reCAPTCHA after submission
          recaptchaRef.current.reset()
          if (resp.status === 409) {
            setStep('alreadyBooked')
            setBookedData(text)
            return
          }

          if (resp.status === 417) {
            setStep('bookingNotAvailable')
            setBookedData(text)
            return
          }

          if (resp.status == 200) {
            setBookedData(text)
            setResult(data)
            reset()
            requestAnimationFrame(() => scrollTo(0, 0))
            setStep('confirmation')

            const productsList: Smartphone[] =
              getLocalStorage(`cacheShopData_${request.shopId}`) ?? []
            const selectedProduct = productsList.find(
              (item) => item.reference === request.smartphoneReference
            )

            if (selectedProduct?.quantity === 1) {
              const updatedProductsList = productsList.filter(
                (item) => item.reference !== request.smartphoneReference
              )
              setLocalStorage(
                `cacheShopData_${request.shopId}`,
                updatedProductsList
              )
            }
            return
          }

          setFormError({
            type: 'required',
            message:
              "Une erreur est survenue lors de l'envoi de la réservation",
          })
        }
      }
    } catch (e) {
      console.error(e)
      setFormError({
        type: 'required',
        message: "Une erreur est survenue lors de l'envoi de la réservation",
      })
    }
  })

  const breadcrumbItems = [
    { label: 'Choix de la boutique', href: '/smartphone' },
    { label: 'Liste des reconditionnés', href: '/smartphone/' + shopId },
    { label: modele, href: '' },
  ]

  const priceFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  })

  const vowelRegex = /^[aeiouAEIOU]$/
  const articleL = vowelRegex.test(modele.charAt(0)) ? "l'" : 'le '
  const articleD = vowelRegex.test(modele.charAt(0)) ? "de l'" : 'du '
  const defaultImage =
    'https://asset-fr.trepidai.pro/model-information/SMA-DEFAULT.png'

  const steps: Step[] = [
    {
      number: 1,
      title: 'Inspection Initiale ',
      description:
        ': Chaque ' +
        modele +
        ' est soumis à une inspection approfondie. Nos techniciens ' +
        "qualifiés évaluent l'état général du téléphone, en vérifiant notamment l'écran, le châssis, " +
        'la batterie, les composants internes et les fonctionnalités.',
    },
    {
      number: 2,
      title: 'Diagnostic et Test ',
      description:
        ": Après l'inspection initiale, " +
        articleL +
        modele +
        ' est soumis à un diagnostic complet. Nous ' +
        'utilisons des outils spécialisés pour évaluer les performances du téléphone, tester toutes ses ' +
        "fonctionnalités, y compris l'appareil photo, le tactile, la connectivité réseau et les capteurs. " +
        'Tout dysfonctionnement ou problème est identifié et noté pour une réparation ultérieure.',
    },
    {
      number: 3,
      title: 'Réparation et Remplacement des Pièces ',
      description:
        ': Une fois les problèmes identifiés, notre équipe de techniciens expérimentés procède aux ' +
        "réparations nécessaires. Les pièces défectueuses sont remplacées par des pièces d'origine ou des pièces" +
        ' de qualité équivalente, garantissant ainsi la fiabilité et la performance ' +
        articleD +
        modele +
        ' reconditionné.',
    },
    {
      number: 4,
      title: 'Définition du grade ',
      description:
        ': Lorsque sa réparation est terminée, nos techniciens experts définissent le grade ' +
        articleD +
        modele +
        ' reconditionné selon les critères suivants :',
      subDescription: [
        'Grade A : le produit est en excellent état et ses performances sont équivalentes à un produit neuf.',
        'Grade B : le produit est en bon état, mais peut montrer quelques micro-rayures peu visibles.',
        'Grade C : le produit est parfaitement fonctionnel mais montre des signes d’usure plus visibles ' +
          'comme des rayures ou micro-impacts.',
      ],
    },
    {
      number: 5,
      title: 'Nettoyage Approfondi ',
      description:
        ': Après les réparations, ' +
        articleL +
        modele +
        ' passe par une étape de nettoyage approfondi.' +
        ' Toutes les traces de saleté, de poussière et de résidus sont éliminées, assurant ' +
        'un aspect esthétique impeccable et une expérience utilisateur agréable.',
    },
    {
      number: 6,
      title: 'Test Final et Certification ',
      description:
        ": Avant d'être mis en vente, chaque " +
        modele +
        ' reconditionné est soumis à un dernier test ' +
        'de qualité. Nous nous assurons que toutes les fonctionnalités du téléphone fonctionnent parfaitement et' +
        ' que sa performance est conforme aux normes élevées de SAVE. Une fois approuvé, le téléphone est ' +
        "certifié prêt à l'emploi et mis en vente de nos magasins Save avec une garantie de 24 mois.",
    },
  ]

  return (
    <>
      {loading && (
        <BookingContent.SpinnerContainer>
          <BookingContent.Spinner />
        </BookingContent.SpinnerContainer>
      )}

      {!loading && smartphoneList.length === 0 && (
        <div
          style={{
            margin: '50px 0',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3 style={{ margin: '50px 0' }}>
            Désolé, le modèle que vous recherchez n'est plus disponible.
          </h3>
          {shops.find((shop) => shop.shopId === shopId) ? (
            <>
              <a
                className="noDataButton"
                href={'/smartphone/' + shopId}
                style={{ margin: 0 }}
              >
                <img src={shopIcon} alt="Shop Icon" /> Retourner à la boutique
              </a>
            </>
          ) : (
            <>
              <a
                className="noDataButton"
                href="/smartphone/"
                style={{ margin: 0 }}
              >
                <img src={shopIcon} alt="Shop Icon" /> Retourner à la sélection
                des boutiques
              </a>
            </>
          )}
        </div>
      )}

      {!loading && smartphoneList.length > 0 && (
        <BookingContent>
          <Helmet>
            <meta
              name="description"
              content={
                'Achetez le ' +
                selectedSmartphone.marque.toUpperCase() +
                ' ' +
                selectedSmartphone.modele +
                ' reconditionné chez ' +
                shops.find((shop) => shop.shopId === pathname[2])?.shopName +
                '. ' +
                'Découvrez ses caractéristiques complètes : performances, design, autonomie et plus encore. ' +
                'Réservez votre smartphone reconditionné maintenant et profitez de nos offres exclusives. ' +
                'Garantie incluse.'
              }
              data-react-helmet="true"
            />
          </Helmet>

          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="recos-title">Sélection de votre smartphone</span>
              <a className="shopName" href="/smartphone">
                {shops.find((shop) => shop.shopId === pathname[2])?.shopName}
              </a>
              <BookingContent.Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="selection-container">
              <div className="image-container">
                <img
                  src={selectedSmartphone.photo ?? defaultImage}
                  alt={''}
                  className="smartphone-image"
                />
              </div>

              <div className="choice-info">
                <h1 className="modele-title">
                  {selectedSmartphone.marque.toUpperCase() + ' ' + modele}
                </h1>
                <p className="info-label">
                  <p>
                    <img
                      src="/images/smartphone/savepicto.svg"
                      alt={''}
                      className="flag-icon"
                    />
                    Reconditionné <b>chez Save</b>
                  </p>
                  <p>
                    <img
                      src="/images/smartphone/check.svg"
                      alt={''}
                      className="check-icon"
                    />
                    Garantie <b>2 ans</b>
                  </p>
                  <p>
                    <img
                      src="/images/smartphone/Pay10.svg"
                      alt={''}
                      className="pay10-icon"
                    />
                    Paiement en <b>10 fois sans frais</b>
                  </p>
                </p>
              </div>

              <div className="choice-sub-container">
                <div>
                  <b className="choice-label">
                    Choisissez la capacité de stockage
                    <br />
                  </b>
                  <div className="button-container">
                    {capacityOptions.map((capacity) => (
                      <BookingContent.SelectionButton
                        key={capacity}
                        onClick={() =>
                          handleCapacityChange(smartphoneList, capacity)
                        }
                        selected={capacity === selectedCapacity}
                        className="capacity-button"
                      >
                        <p className="capacity-label">{capacity}</p>
                      </BookingContent.SelectionButton>
                    ))}
                  </div>
                </div>

                <div>
                  <b className="choice-label">
                    Choisissez la couleur
                    <br />
                  </b>
                  <div className="button-container">
                    {colorOptions.map((color) => (
                      <BookingContent.SelectionButton
                        key={color}
                        onClick={() =>
                          handleColorChange(
                            smartphoneList,
                            selectedCapacity,
                            color
                          )
                        }
                        selected={color === selectedColor}
                        className="color-button"
                      >
                        <p className="color-label">
                          <BookingContent.Circle color={color} />
                          {BookingContent.rewrite(color)}
                        </p>
                      </BookingContent.SelectionButton>
                    ))}
                  </div>
                </div>

                <div>
                  <b className="choice-label">
                    Choisissez l'état
                    <br />
                  </b>
                  <div
                    className="button-container"
                    style={{ flexWrap: 'nowrap' }}
                  >
                    {gradeOptions.map((grade) => (
                      <BookingContent.SelectionButton
                        key={grade}
                        onClick={() =>
                          handleGradeChange(
                            smartphoneList,
                            selectedCapacity,
                            selectedColor,
                            grade
                          )
                        }
                        selected={grade === selectedGrade}
                        className="grade-button"
                      >
                        <div className="grade-label">
                          <p>{BookingContent.rewrite(grade)}</p>
                          <p
                            style={{
                              color:
                                grade === selectedGrade
                                  ? 'inherit'
                                  : theme.colors.secondary,
                            }}
                          >
                            {priceFormatter.format(
                              getSmartphone(
                                smartphoneList,
                                selectedCapacity,
                                selectedColor,
                                grade
                              ).buyAmountTTC
                            )}
                          </p>
                          <p>
                            ou{' '}
                            {priceFormatter.format(
                              getSmartphone(
                                smartphoneList,
                                selectedCapacity,
                                selectedColor,
                                grade
                              ).buyAmountTTC / 10
                            )}{' '}
                            x 10
                          </p>
                        </div>
                      </BookingContent.SelectionButton>
                    ))}
                  </div>
                </div>

                <div>
                  <BookingContent.BookingContainer>
                    <img src="/images/smartphone/shop_Icon_blue.svg" alt={''} />
                    <span>Votre {modele} est disponible à</span>
                    <span>
                      <img src="/images/save.svg" alt={'saveIcon'} />
                      {shops.find((shop) => shop.shopId === shopId)?.shopName ??
                        ''}
                    </span>
                    <span>
                      <img
                        src="/images/smartphone/location.svg"
                        alt={'locationIcon'}
                      />
                      {shops.find((shop) => shop.shopId === shopId)
                        ?.shopFullAddress ?? ''}
                    </span>
                    <BookingContent.BookingButton
                      onClick={handleSelectionButton}
                    >
                      <img src="/images/smartphone/calendar.svg" alt={''} />
                      Réserver mon reconditionné
                    </BookingContent.BookingButton>
                  </BookingContent.BookingContainer>
                </div>
              </div>
            </div>
          </>

          {step === 'booking' && (
            <BookingForm
              imgUrl={selectedSmartphone.photo ?? defaultImage}
              color={selectedColor}
              capacity={selectedCapacity}
              grade={selectedGrade}
              price={selectedSmartphone.buyAmountTTC}
              modele={modele}
              handleCloseToSelection={handleCloseToSelection}
              register={register}
              errors={errors}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              formError={formError}
              result={result}
              recaptchaRef={recaptchaRef}
              onBookingSubmit={onBookingSubmit}
            />
          )}

          {step === 'confirmation' && (
            <BookingConfirmation
              modele={bookedData.modele}
              shopName={
                shops.find((shop) => shop.shopId === bookedData.shopId)
                  ?.shopName ?? ''
              }
              shopAddress={
                shops.find((shop) => shop.shopId === bookedData.shopId)
                  ?.shopFullAddress ?? ''
              }
              handleCloseToHome={handleCloseToHome}
            />
          )}

          {step == 'alreadyBooked' && (
            <BookingError
              modele={bookedData.modele}
              shopName={
                shops.find((shop) => shop.shopId === bookedData.shopId)
                  ?.shopName ?? ''
              }
              shopAddress={
                shops.find((shop) => shop.shopId === bookedData.shopId)
                  ?.shopFullAddress ?? ''
              }
              handleCloseToHome={handleCloseToHome}
            />
          )}

          {step == 'bookingNotAvailable' && (
            <BookingNotAvailableError
              link={recLink + shopId}
              handleCloseToHome={handleCloseToHome}
            />
          )}
          <p className="info-reconditionnement">
            <b className="chez-save">Chez Save,</b>
            &nbsp;chaque {modele} passe par un processus méticuleux pour
            garantir une qualité et une performance optimales. Voici les étapes
            principales du reconditionnement.
          </p>

          <div>
            <BookingContent.StepList steps={steps} />
          </div>

          {recommendedList.length > 0 && (
            <div style={{ marginTop: '40px' }}>
              <h2 className="autres-reconditionnes">
                D'autres reconditionnés pourraient vous plaire
              </h2>
              <div className="smartphone-card-list">
                {recommendedList.map((smartphone, index) => (
                  <SmartphoneCard key={index} {...smartphone} />
                ))}
              </div>
            </div>
          )}
          <CustomerReviews shopId={shopId} />
        </BookingContent>
      )}
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Réservation de votre reconditionné',
}

export default Object.assign(smartphone, {
  layoutProps,
})
