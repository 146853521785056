import React from 'react'
import BookingContent from '../../../../components/BookingContent'
import Button from '../../../../components/Button'

interface BookingErrorProps {
  link: string
  handleCloseToHome: () => void
}

const BookingNotAvailableError: React.FC<BookingErrorProps> = ({
  link,
  handleCloseToHome,
}) => {
  return (
    <BookingContent.Modal>
      <BookingContent.ModalContent>
        <BookingContent.Close onClick={handleCloseToHome}>
          &times;
        </BookingContent.Close>
        <BookingContent.Title>
          Désolé, ce téléphone a été réservé à l'instant !{' '}
        </BookingContent.Title>
        <div style={{ margin: '40px 0', textAlign: 'center', lineHeight: '2' }}>
          <h2>
            Nous sommes désolés, mais le téléphone que vous souhaitez réserver
            vient tout juste d'être réservé par un autre client.{' '}
          </h2>
          <h2>Nous vous invitons à explorer nos autres options disponibles.</h2>
          <h2>Merci pour votre compréhension !</h2>
          <a href={link} style={{ textDecoration: 'none' }}>
            <Button style={{ display: 'inline', marginTop: '30px' }}>
              Sélectionner un nouveau smartphone
            </Button>
          </a>
        </div>
      </BookingContent.ModalContent>
    </BookingContent.Modal>
  )
}

export default BookingNotAvailableError
